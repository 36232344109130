<template>
    <div>
        <span class="small_title">{{ title }}</span>
        <span class="small_content">
            {{ info }}
        </span>
    </div>
</template>

<script>
export default {
    props: ["title", "info"],
};
</script>
<style scoped>
.small_title {
    font-weight: bold;
}

.small_content {
    display: inline-block;
    margin: 10px 20px;
    color: grey;
}
</style>
