<template>
    <div class="advLayout_wrap">
        <div class="table_top">
            <div class="title_manage">客服后台管理</div>
            <div class="button_search">
                <el-autocomplete
                    class="inline-input"
                    v-model="searchCompanyName"
                    @keyup.enter.native="selectInfo()"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入公司名搜索"
                    :trigger-on-focus="false"
                    @select="handleSelect"
                ></el-autocomplete>
                <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="selectInfo()"
                ></el-button>
                <el-button
                    type="primary"
                    style="float: right"
                    @click="toAddDialog()"
                >
                    新增广告信息
                </el-button>
            </div>
        </div>
        <div class="div_content">
            <div class="list_content">
                <el-table
                    ref="table"
                    :data="tableData"
                    highlight-current-row
                    @row-click="showListDetail"
                    style="width: 100%"
                    :header-cell-style="{ 'text-align': 'center' }"
                    :cell-style="{ 'text-align': 'center' }"
                >
                    <el-table-column
                        label="公司ID"
                        prop="id"
                        width="80"
                    ></el-table-column>
                    <el-table-column
                        label="公司名称"
                        prop="name"
                        width="250"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        label="所在地"
                        prop="position"
                    ></el-table-column>
                    <el-table-column
                        label="供应商类型"
                        prop="supType"
                        width="200"
                    >
                        <template slot-scope="scope">
                            <el-tag
                                size="small"
                                v-for="item in scope.row.supType"
                                :key="item"
                                style="margin-right: 5px"
                                :type="judgeType(item, 'color')"
                            >
                                {{ judgeType(item, "name") }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button
                                @click.stop="toupdate(scope.row)"
                                type="text"
                                size="small"
                            >
                                修改
                            </el-button>
                            <el-button
                                @click.stop="todelete(scope.row.id)"
                                type="text"
                                size="small"
                                style="color: red"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    style="text-align: center; margin: 15px"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="pageSize"
                    :current-page="pagePosition"
                ></el-pagination>
            </div>
            <div class="show_content">
                <div v-if="isshowDetail == false">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <div v-else style="display: flex; width: 100%">
                    <img class="company_img" :src="transData.logoUrl" />
                    <div class="text_content">
                        <ad-contain
                            title="名称："
                            :info="curCompanyInfo.name"
                        />
                        <ad-contain
                            title="所在地："
                            :info="curCompanyInfo.position"
                        />
                        <ad-contain title="供应商类型：" :info="typeStr" />
                        <ad-contain
                            title="地址："
                            :info="curCompanyInfo.address"
                        />
                        <ad-contain title="网址" :info="curCompanyInfo.url" />
                        <div>
                            <span class="small_title">简介：</span>
                            <div class="ad_layout_markdown_wrap">
                                <markdown-editor
                                    mode="preview"
                                    preview-background="#ffffff"
                                    font-size="12px"
                                    v-model="curCompanyInfo.profile"
                                    :box-shadow="false"
                                ></markdown-editor>
                            </div>
                        </div>
                        <div>
                            <span class="small_title">业务优势：</span>
                            <div class="ad_layout_markdown_wrap">
                                <markdown-editor
                                    mode="preview"
                                    preview-background="#ffffff"
                                    font-size="12px"
                                    v-model="curCompanyInfo.superiority"
                                    :box-shadow="false"
                                ></markdown-editor>
                            </div>
                        </div>
                        <div style="margin-top: 10px">
                            <div class="small_title">招聘广告信息：</div>
                            <el-table
                                :data="curCompanyInfo.supJobList"
                                stripe
                                style="max-height: 250px; width: 100%"
                            >
                                <el-table-column
                                    prop="position"
                                    label="职位名称"
                                ></el-table-column>
                                <el-table-column
                                    prop="numbers"
                                    label="招聘人数"
                                ></el-table-column>
                                <el-table-column
                                    prop="requirement"
                                    label="招聘要求"
                                ></el-table-column>
                                <el-table-column
                                    prop="salary"
                                    label="薪资/月"
                                ></el-table-column>
                            </el-table>
                        </div>
                        <div style="margin-top: 10px">
                            <span class="small_title">价格服务：</span>
                            <el-table
                                :data="curCompanyInfo.supServices"
                                stripe
                                style="max-height: 250px; margin: 10px 0"
                            >
                                <el-table-column
                                    prop="name"
                                    label="服务名称"
                                ></el-table-column>
                                <el-table-column
                                    prop="price"
                                    label="服务价格"
                                ></el-table-column>
                                <el-table-column
                                    prop="profile"
                                    label="服务简介"
                                ></el-table-column>
                            </el-table>
                        </div>
                        <div
                            v-if="
                                curCompanyInfo.supResourceList.find(
                                    (item) => item.type === 2,
                                )
                            "
                        >
                            <div class="small_title">轮播图库：</div>
                            <span
                                class="small_content"
                                v-for="(
                                    item, index
                                ) in curCompanyInfo.supResourceList"
                                :key="index"
                            >
                                <img
                                    v-if="item.type == 2"
                                    class="carsousel_span"
                                    :src="item.uri"
                                    alt="放大查看"
                                    @click="lookPicture(item.uri)"
                                />
                                <el-dialog
                                    :visible.sync="picturedialogVisible"
                                    width="20%"
                                >
                                    <img
                                        class="carsousel1_span"
                                        :src="currentPicture"
                                    />
                                </el-dialog>
                            </span>
                        </div>
                        <div
                            v-if="
                                curCompanyInfo.supResourceList.find(
                                    (item) => item.type === 3,
                                )
                            "
                        >
                            <span class="small_title">宣传视频：</span>
                            <el-button
                                type="text"
                                @click="
                                    toLookMp4(
                                        curCompanyInfo.supResourceList.filter(
                                            (item) => item.type === 3,
                                        )[0].uri,
                                    )
                                "
                            >
                                预览
                            </el-button>
                        </div>
                        <div style="padding-top: 20px">
                            <div
                                class="small_title"
                                style="margin-bottom: 10px"
                            >
                                联系人：
                            </div>
                            <el-table
                                :data="curCompanyInfo.supContactList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    v-for="worker in workerColumn"
                                    :prop="worker.key"
                                    :label="worker.label"
                                    :key="worker.key"
                                ></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UpdateDiaolpg from "./UpdateAdvertisementDialog.vue";
import MarkdownEditor from "@/components/common/MarkdownEditor.vue";
import AdContain from "./component/AdContain.vue";
import {
    queryAdvDeatil,
    queryAdDetail,
    searchAdInfo,
    deleteAdv,
} from "@/api/advertisement";
import { workerColumn } from "@/data";
export default {
    components: {
        UpdateDiaolpg,
        MarkdownEditor,
        AdContain,
    },
    data() {
        return {
            picturedialogVisible: false, //轮播图弹窗
            tableData: [], //列表数据
            isshowDetail: false, //暂无数据
            restaurants: [], //搜索框建议
            searchCompanyName: "",
            pageSize: 10,
            pagePosition: 1,
            // 过度数据(后端不接收多余数据，所以过渡数据单独拿出来)
            transData: {
                logoUrl: "", // 供应商logo
                imageList: [], // 轮播图列表
                areaSelect: "", //选中的省市区数据
                MP4List: [], // 宣传视频列表
            },
            // 公司详情信息
            curCompanyInfo: {
                supType: [], // 供应商类型
                position: "宁波", // 供应商位置
                name: "", // 供应商名称
                address: "", // 供应商地址
                url: "", // 官网地址
                profile: "", // 简介
                province: "", // 省
                area: "", //区
                city: "", //市
                email: "", // 邮箱
                phone: "", //电话
                superiority: "", // 业务优势
                supJobList: [], // 招聘列表
                supServices: [], // 服务列表
                supContactList: [], //联系人列表
                superiorityBusinessCarTeam: [], //车队优势业务
                superiorityBusinessCustoms: [], //报关行优势业务
                superiorityBusinessForwarder: [], //货代优势业务
                superiorityCourseCarTeam: [], //车队优势航线
                superiorityCourseCustoms: [], //报关行优势航线
                superiorityCourseForwarder: [], //	货代优势航线
            },
            urlList: [], // 公司详情轮播图片和宣传视频列表
            pageTotal: 0,
            currentPicture: "", //正在放大的轮播图
            activeClass: "",
            workerColumn,
        };
    },
    computed: {
        typeStr() {
            return this.curCompanyInfo.supType
                .map((item) => this.judgeType(item, "name"))
                .join("|");
        },
    },
    mounted() {
        this.query();
    },
    methods: {
        // 判断类型
        judgeType(value, type) {
            const typeName = {
                1: "货代",
                2: "车队",
                3: "报关行",
            };
            const typeColor = {
                1: "success",
                2: "primary",
                3: "info",
            };
            return type === "name" ? typeName[value] : typeColor[value];
        },
        getCurCompanyInfo(id) {
            queryAdDetail(id).then(({ data }) => {
                this.curCompanyInfo = {
                    ...data.data,
                    superiorityBusinessCustoms: data.data
                        .superiorityBusinessCustoms
                        ? data.data.superiorityBusinessCustoms[0]
                        : "",
                };
                // 赋值
                // 1.头像，2.轮播图中的图片，3.视频资源
                this.curCompanyInfo.supResourceList.forEach((item) => {
                    if (item.type === 1) {
                        this.transData.logoUrl = item.uri;
                    } else if (item.type === 2) {
                        this.transData.imageList.push(item.uri);
                    } else if (item.type === 3) {
                        this.transData.MP4List = [item.uri];
                    }
                });
                this.isshowDetail = true;
            });
        },
        // 分页查询公司部分信息
        query() {
            let param = {
                size: this.pageSize,
                current: this.pagePosition,
            };

            queryAdvDeatil(param).then(({ data }) => {
                this.pageTotal = data.data.totalPage;
                // 提示信息
                this.restaurants = (data.data || []).map((item) => ({
                    value: item.name,
                }));
                this.tableData = data.data;
                if (this.tableData && this.tableData.length > 0) {
                    this.getCurCompanyInfo(this.tableData[0].id);
                    this.$refs.table.setCurrentRow(this.tableData[0], true);
                }
            });
        },
        lookPicture(url) {
            //放大查看图片
            this.picturedialogVisible = true;
            this.currentPicture = url;
        },
        //搜索广告名字
        selectInfo() {
            this.searchCompanyName
                ? searchAdInfo({ name: this.searchCompanyName }).then(
                      ({ data }) => {
                          //操作
                          this.tableData = data.data;
                      },
                  )
                : this.query();
        },
        todelete(id) {
            //删除公司信息
            deleteAdv(id).then(async () => {
                await this.query();
                this.$message.success("删除成功");
            });
        },

        toupdate(item) {
            let newpage = this.$router.resolve({
                path: "/advUpdateManage",
                query: {
                    id: item.id,
                },
            });
            window.open(newpage.href, "_blank");
        },

        toAddDialog() {
            let newpage=this.$router.resolve({
                path: "/advAddManage",
            });
            //打开新增广告信息组件
            window.open(newpage.href, "_blank");
        },

        handleSelect(item) {
            this.searchCompanyName = item.value;
        },

        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString
                ? restaurants.filter(this.createFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },

        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1
                );
            };
        },

        handleSizeChange(val) {
            this.pageSize = val;
            this.pagePosition = 1;
            this.query();
        },

        //分页获取当前页
        handleCurrentChange(val) {
            this.pagePosition = val;
            this.query();
        },

        showListDetail(row) {
            //点击改行传递数据显示
            const id = row.id;
            this.getCurCompanyInfo(id);
        },

        toLookMp4(data) {
            //预览视频
            window.open(data, "_blank");
        },
    },
};
</script>

<style lang="stylus" scoped>
.ad_layout_markdown_wrap{
  padding: 10px 0;
}
.advLayout_wrap{
    background: #ffffff;
    margin: 20px;
    padding: 0 10px 10px 10px;
    // background:rgb(241,243,246);
}
.title_manage {
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 60px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.button_search {
    padding: 1rem;
}

.div_content {
    display: flex;
}

.text_content {
    position: relative;
    max-width: 80%;
    flex-grow: 1;
}

.list_content {
    width: 40%;
    border: 1px solid rgba(0,0,0,0.1)
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;
}

.show_content {
    flex-grow: 1;
    font-size: 13px;
    min-height: 500px;
    background: #ffffff;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;
    overflow: hidden;
    margin-left: 1rem;
    padding-bottom: 20px;
}

.div_forwarder,
.div_fleet,
.div_inOut {
    margin: 0 2rem 0 2rem;
    border-radius: 8px;
}

.div_inOut {
    border: 1px solid lightgrey;
}

.div_fleet {
    border: 1px solid lightblue;
}

.div_forwarder {
    border: 1px solid lightgreen;
}

.div_twotype {
    width: 200px;
    overflow: hidden; // 超出的文本隐藏
    text-overflow: ellipsis; // 溢出用省略号显示
    white-space: nowrap; // 溢出不换行
}

.company_img {
    border: 1px solid lightgrey;
    padding: 8px;
    width: 80px;
    height: 80px;
    margin: 20px;
    object-fit: contain;
}

.small_title {
    font-weight: bold;
}

.small_content {
    display: inline-block;
    margin: 10px 20px;
    color: grey;
}

.people_span {
    width: 120px;
    height: 100px;
    margin-left: 20px;
    border: 1px solid lightgrey;
}

.carsousel_span {
    width: 120px;
    height: 100px;
    margin-left: 20px;
    border: 1px solid lightgrey;
    cursor: pointer;
}
.carsousel1_span{
    width: 300px;
    height: 200px;
    margin:0 auto;
}
.people_div {
    display: inline-block;
    margin: 2rem;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .5);
    background: #dcdcdc;
    font-size: 15px;
}

.people_div {
    display: inline-block;
    margin: 2rem;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .5);
    background: #dcdcdc;
    font-size: 15px;
}
</style>
