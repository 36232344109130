export const takeoutTable = [
    {
        prop: "outdoor_time",
        label: "提箱时间",
        show: true,
    },
    {
        prop: "yardname",
        label: "提箱地点",
        show: true,
    },
    {
        prop: "ctnno",
        label: "箱号",
        width: 120,
        show: true,
    },
    {
        prop: "sealno",
        label: "封号",
        width: 120,
        show: true,
    },
    {
        prop: "sizetype",
        label: "箱尺寸",
        width: 80,
        show: true,
    },
    {
        prop: "truck_license",
        label: "车牌号",
        width: 120,
        show: true,
    },
];

export const workerColumn = [
    {
        label: "姓名",
        key: "name",
    },
    {
        label: "职位",
        key: "position",
    },
    {
        label: "电话",
        key: "phone",
    },

    {
        label: "邮箱",
        key: "email",
    },
];
